import React from 'react';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { auth, db } from '../firebaseConfig'

const AuthContext = React.createContext()

export const useAuthContext = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [admin, setAdmin] = useState(false)
    const [userData, setUserData] = useState()
    const [showAlert, setShowAlert] = useState({
        variant: '',
        status: false,
        msg: ''
    })

    useEffect(() => {
        setLoading(true)
        auth.onAuthStateChanged((user) => {
            setCurrentUser(user)
            currentUser && db.collection('admins').doc(currentUser.uid).onSnapshot((doc) => {
                if (doc.exists) {
                    setAdmin(true)
                    setUserData(doc.data())
                }
                setLoading(false)
            })
            !currentUser && setLoading(false)
        })
    }, [currentUser])

    const signIn = (email, pass) => {
        return auth.signInWithEmailAndPassword(email, pass)
    }
    const logOut = () => {
        return auth.signOut()
    }

    const value = {
        currentUser,
        signIn,
        logOut,
        userData,
        setUserData,
        showAlert,
        setShowAlert,
        admin,
        setAdmin,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

export default AuthContext