import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { db, storage } from './../../../firebaseConfig';
import VideoCard from './VideoCard/';
import { useAuthContext } from './../../../contexts/AuthContext';
const YTVideos = () => {
    // Videos State
    const [videos, setVideos] = useState([])
    const [totalVideos, setTotalVideos] = useState(0)

    // Modal Controls
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // Alerts
    const { setShowAlert } = useAuthContext()

    // Input Refs
    const titleRef = useRef(null)
    const subtitleRef = useRef(null)
    const linkRef = useRef(null)
    const tagsRef = useRef(null)
    const dateRef = useRef(null)
    const [imageUrl, setImageUrl] = useState('')
    const [progress, setProgress] = useState(null)
    // Add Videos to DB
    const addVideo = (e) => {
        e.preventDefault()
        db.collection('ytVids').add({
            image: imageUrl,
            link: linkRef.current.value,
            published: new Date(dateRef.current.value),
            subtitle: subtitleRef.current.value,
            tags: tagsRef.current.value.split(','),
            title: titleRef.current.value
        }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Video Added'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            handleClose()
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    // Upload Image
    const type = ['image/png', 'image/jpeg', 'image/jpg']
    const uploadImage = (e) => {
        const file = e.target.files[0]
        if (file) {
            if (type.includes(file.type)) {
                const uploadTask = storage.ref(`events/${file.name}`).put(file)
                uploadTask.on('state_changed', (snapshot) => {
                    // progress function
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    setProgress(progress)
                }, (error) => {
                    // error function
                    console.log(error)
                    setShowAlert({
                        variant: 'danger',
                        status: true,
                        msg: 'Something Went Wrong! Try Again'
                    })
                    setTimeout(() => {
                        setShowAlert({
                            variant: '',
                            status: false,
                            msg: ''
                        })
                    }, 2000)
                }, () => {
                    // complete function
                    storage.ref('events').child(file.name).getDownloadURL().then(url => {
                        setImageUrl(url)
                    })
                })
            } else {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Invalid File Type'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            }
        }
    }

    // Fetch All Video Data
    useEffect(() => {
        db.collection('ytVids').orderBy('published', 'desc').onSnapshot(
            snapshot => {
                setVideos(snapshot.docs.map(
                    doc => ({
                        id: doc.id,
                        image: doc.data().image,
                        link: doc.data().link,
                        published: doc.data().published.toDate(),
                        subtitle: doc.data().subtitle,
                        tags: doc.data().tags,
                        title: doc.data().title
                    })
                ))
                setTotalVideos(snapshot.docs.length)
            }
        )
    }, [])
    return (
        <div>
            <div className='d-flex flex-column py-3'>
                <div className="header">
                    <div className="totalVideos">
                        <b>
                            Total Videos: {totalVideos}
                        </b>
                    </div>
                    <Button className='my-2' variant="primary" onClick={handleShow}>
                        Add Video
                    </Button>
                </div>
            </div>
            <div className='videos py-1 d-flex flex-wrap'>
                {videos.map(({ id, link, image, published, subtitle, title, tags, }) => (
                    <VideoCard
                        id={id}
                        key={id}
                        link={link}
                        image={image}
                        date={published.toLocaleDateString('en-US')}
                        subtitle={subtitle}
                        tags={tags.join(',')}
                        title={title}
                    />
                ))}
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add New Published Video</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addVideo}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" placeholder="Video Title" ref={titleRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="subtitle">
                            <Form.Control type="text" placeholder="Video Subtitle" ref={subtitleRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="file">
                            <Form.Control type="file" onChange={uploadImage} />
                            <br />
                            {progress && progress < 100 ? (<div className='text-danger m-2'>
                                Uploading... {progress}%
                            </div>)
                                : (progress === 100 && <div className='text-success m-2'>
                                    Uploaded
                                </div>)
                            }
                        </Form.Group>
                        <Form.Group className='my-2' controlId="image">
                            <Form.Control className='disabled' type="text" placeholder="Video Image URL" value={imageUrl} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="link">
                            <Form.Control type="text" placeholder="Video Link" ref={linkRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="tags">
                            <Form.Control type="text" placeholder="Video Tags - Use Comma, No Space" ref={tagsRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="date">
                            <Form.Control type="date" placeholder="Published On" ref={dateRef} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={addVideo}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>

    )
}

export default YTVideos
