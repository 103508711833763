import React from 'react'
import { Card, Table } from 'react-bootstrap'

const Stats = ({ users, interests }) => {
    return (
        <div className="stats">
            <Card className='m-1 p-2 d-flex align-items-center justify-content-center'>
                <Table className='m-0' bordered hover>
                    <tbody>
                        <tr>
                            <th style={{
                                width: '50%'
                            }}>Total Users</th>
                            <td>{users.length}</td>
                        </tr>
                        <tr>
                            <th style={{
                                width: '50%'
                            }}>Profile Completed</th>
                            <td>{
                                users.filter(user => user.profileComplete).length
                            }
                            </td>
                        </tr>
                        <tr>
                            <th style={{
                                width: '50%'
                            }}>Gender</th>
                            <td>
                                <Table className='m-0' hover bordered>
                                    <tbody>
                                        <tr>
                                            <th>
                                                Male
                                            </th>
                                            <td>
                                                {
                                                    users.filter(user => user.gender === 'male').length
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Female
                                            </th>
                                            <td>
                                                {
                                                    users.filter(user => user.gender === 'female').length
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Others
                                            </th>
                                            <td>
                                                {
                                                    users.filter(user => user.gender === 'others').length
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <th style={{
                                width: '50%'
                            }}>
                                Interests
                            </th>
                            <td>
                                <Table className='m-0' hover bordered>
                                    <tbody>
                                        {
                                            interests.map(interest => (
                                                <tr key={interest.id}>
                                                    <th>
                                                        {interest.title}
                                                    </th>
                                                    <td>
                                                        {
                                                            users.filter(user => user.profileComplete && user.interests.includes(interest.title)).length
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
        </div>
    )
}

export default Stats
