import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useAuthContext } from '../../../contexts/AuthContext';
import Spacer from '../../Utilities/Spacer';
import { db } from './../../../firebaseConfig';
import Transaction from './Transaction/index';

const DoCoCoins = () => {
    const types = ['debit', 'credit'];
    const [transactions, setTransactions] = useState([]);
    // Alerts
    const { setShowAlert } = useAuthContext()
    // Modal Controls
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    // Input Refs for amount, user,type,remark
    const [amount, setAmount] = useState('');
    const [user, setUser] = useState('');
    const [type, setType] = useState('');
    const [remarks, setRemarks] = useState('');
    // Add Transaction
    const addTransaction = (e) => {
        e.preventDefault()
        db.collection('transactions').add({
            amount: parseInt(amount),
            user: user,
            type: type,
            remarks: remarks,
            datetime: new Date()
        }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Category Added'
            })
            handleClose()
            setAmount('')
            setUser('')
            setType('')
            setRemarks('')
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)

        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }
    // get all transactions
    useEffect(() => {
        db.collection('transactions').orderBy('datetime', 'desc').onSnapshot(snapshot => {
            setTransactions(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    amount: doc.data().amount,
                    datetime: doc.data().datetime,
                    type: doc.data().type,
                    user: doc.data().user,
                    remarks: doc.data().remarks
                }
            )));
        });
    }, []);
    return (
        <div>
            <hr />
            <h4>Total Transactions ({transactions.length})</h4>
            <Spacer h='8px' />
            <Button variant="primary" onClick={handleShow}>
                Add New Transaction
            </Button>
            <Spacer h='8px' />
            <div className="TransactionList">
                <Table className="my-2" striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#id</th>
                            <th>User</th>
                            <th>Amount</th>
                            <th>Remarks</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transactions.map(transaction => (
                                <Transaction
                                    id={transaction.id}
                                    key={transaction.id}
                                    amount={transaction.amount}
                                    datetime={transaction.datetime.toDate().toLocaleString()}
                                    type={transaction.type}
                                    user={transaction.user}
                                    remarks={transaction.remarks}
                                />
                            ))
                        }
                    </tbody>
                </Table>

            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add New Transaction</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addTransaction}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control
                                type="text"
                                placeholder="Transaction User"
                                value={user}
                                onChange={e => setUser(e.target.value)}
                                required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control
                                type="number"
                                placeholder="Transaction Amount"
                                value={amount}
                                onChange={e => setAmount(e.target.value)}
                                required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="link">
                            <Form.Control
                                type="text"
                                placeholder="Transaction Remark"
                                value={remarks}
                                onChange={e => setRemarks(e.target.value)}
                                required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="category">
                            <Form.Control as="select" onChange={(e) => setType(e.target.value)} required>
                                <option value="">Select Transaction Type</option>
                                {types.map((type, index) => (
                                    <option key={index} value={type}>{type}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={addTransaction}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default DoCoCoins

/* Transactions
    - amount
    - datetime
    - remarks
    - type
    - user
*/