import React from 'react'
import { useAuthContext } from './../contexts/AuthContext';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
const AdminRoute = ({ component: Component }, ...rest) => {
    const { currentUser, admin, logOut } = useAuthContext()

    return (
        <Route
            {...rest}
            render={props => {
                return (currentUser) ?
                    (admin ?
                        (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        )
                        :
                        (<div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '30vh'
                            }}
                        >
                            <br />
                            Not an Admin
                            <br />
                            <br />
                            <p onClick={logOut} className="btn btn-danger">Logout</p>
                        </div>))
                    :
                    <Redirect to='/signin' />
            }}
        />
    )
}

export default AdminRoute