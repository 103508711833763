import React, { useRef, useState } from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'
import { useAuthContext } from './../contexts/AuthContext';

export default function SignIn() {
    const emailRef = useRef()
    const passRef = useRef()
    const { signIn, currentUser } = useAuthContext()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    const formSubmit = async (e) => {
        e.preventDefault()
        setError('')
        setLoading(true)
        signIn(emailRef.current.value, passRef.current.value)
            .then(() => {
                history.push('/')
            })
            .catch((error) => {
                setError('User Not Found! Please Sign Up')
            })
        setLoading(false)
    }


    return (currentUser)
        ? (<Redirect to='/' />)
        : (
            <div className='container w-100 vh-100 d-flex justify-content-center align-items-center'>
                <Card>
                    <Card.Header>
                        <h4>Sign In</h4>
                    </Card.Header>
                    <Card.Body>
                        {error && <Alert style={{ width: '400px' }} className='mb-3' variant='danger'>{error}</Alert>}
                        <Form onSubmit={formSubmit} >
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type='email' placeholder='someone@example.com' required ref={emailRef} />
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' placeholder='Enter Password' required ref={passRef} />
                            </Form.Group>
                            <br />
                            <Button disabled={loading} className='w-100' type='submit'>Sign In</Button>
                        </Form>
                    </Card.Body>
                </Card >
            </div >
        )
}