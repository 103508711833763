import React from 'react'
import { useAuthContext } from './../../../contexts/AuthContext';

const Navbar = () => {
    const { logOut } = useAuthContext()
    return (
        <div className="navbar p-3 bg-dark">
            <div className="logo">
                <img src="https://docogen.com/assets/Light/DoCoNation-Alt.png" alt="DoCoNation" width='150px' />
            </div>
            <div className="logout">
                <div onClick={logOut} className="btn btn-danger">Log Out</div>
            </div>
        </div>
    )
}

export default Navbar