import React from 'react'

const Spacer = ({ h }) => {
    return (
        <div
            style={{
                height: h
            }}
        >
        </div>
    )
}

export default Spacer
