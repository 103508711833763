import React, { useState } from 'react'
import { db } from './../../../../firebaseConfig';
import { useAuthContext } from './../../../../contexts/AuthContext';
import { Button, Form, Modal } from 'react-bootstrap';

const Link = ({ title, id, url, priority }) => {
    // Modal Controls
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);

    // Input States
    const [inputTitle, setInputTitle] = useState(title)
    const [inputUrl, setInputUrl] = useState(url)
    const [inputPriority, setInputPriority] = useState(priority)

    // Alert
    const { setShowAlert } = useAuthContext()

    //  Edit Link
    const editLink = (e) => {
        e.preventDefault()
        db.collection('links').doc(id).set({
            url: inputUrl,
            priority: inputPriority,
            title: inputTitle
        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Link Edited'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    // Delete Link
    const delLink = () => {
        db.collection('links').doc(id).delete()
            .then(() => {
                setShowAlert({
                    variant: 'success',
                    status: true,
                    msg: 'Deleted Successfully'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
                setShowDelModal(false)
            }).catch(() => {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Something Went Wrong! Try Again'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            })
    }
    return (
        <tr >
            <td>{priority}</td>
            <td>{title}</td>
            <td><a href={url}>{url}</a></td>
            <td>
                <div className="btn btn-danger mx-1" onClick={e => setShowDelModal(true)} >Delete</div>
                <div className="btn btn-primary mx-1" onClick={e => setShowEditModal(true)}>Edit</div>
            </td>


            <Modal show={showEditModal} onHide={e => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Link</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editLink}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" value={inputTitle} placeholder="Link Title" onChange={e => setInputTitle(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="url">
                            <Form.Control type="text" value={inputUrl} placeholder="Link URL" onChange={e => setInputUrl(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="image">
                            <Form.Control type="number" value={inputPriority} placeholder="Link Priority" onChange={e => setInputPriority(e.target.value)} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={editLink}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showDelModal} onHide={e => setShowDelModal(false)}>
                <Modal.Header>
                    <Modal.Title>Delete Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowDelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={delLink}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </tr>
    )
}

export default Link
