import React from 'react'
import UserCard from './UserCard'

const UserList = ({ users }) => {
    return (
        <div className="userList d-flex flex-column py-2">
            {users.map(user => (
                <UserCard
                    key={user.id}
                    id={user.id}
                    name={user.firstName + " " + user.lastName}
                    email={user.email}
                    phoneNumber={user.phoneNumber}
                    connections={user.connections.length}
                    dateOfBirth={user.dateOfBirth ? user.dateOfBirth.toDate().toDateString() : "-"}
                    createdAt={user.createdAt.toDate().toDateString()}
                    gender={user.gender}
                    profileComplete={user.profileComplete}
                    interests={user.interests ? user.interests : []}
                />
            ))}
        </div>
    )
}

export default UserList
