import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { db } from '../../../firebaseConfig'
import Spacer from '../../Utilities/Spacer';
import UserList from './UserList/index';
import Stats from './Stats/index';

const Users = () => {
    const [users, setUsers] = useState([])
    const [interests, setInterests] = useState([])
    useEffect(() => {
        db.collection('users').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            setUsers(snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })))
        })
        db.collection('interests').onSnapshot(snapshot => {
            setInterests(snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })))
        })
    }, [])
    return (
        <div>
            <hr />
            <h2>Users</h2>
            <Spacer h='16px' />
            <Tabs defaultActiveKey='none'>
                <Tab key='stats' eventKey='stats' title='Stats'>
                    <Stats users={users} interests={interests} />
                    <Spacer h='24px' />
                </Tab>
                <Tab key='users' eventKey='users' title='Users'>
                    <UserList users={users} />
                </Tab>
            </Tabs>
        </div>
    )
}

export default Users

/*
id,
name,
email,
connections,
dateOfBirth,
createdAt,
gender,
interests,
profileComplete,
phoneNumber
*/