import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { db } from '../../../../firebaseConfig';

const Transaction = ({ id, amount, user, datetime, type, remarks }) => {
    // Alert
    const { setShowAlert } = useAuthContext()
    // Modal Controls
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);
    // Inputs
    const [editAmount, setEditAmount] = useState(amount);
    const [editRemarks, setEditRemarks] = useState(remarks);
    // Actions
    const editTransaction = (e) => {
        e.preventDefault()
        db.collection('transactions').doc(id).set({
            amount: parseInt(editAmount),
            remarks: editRemarks,
        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Transaction Edited'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }
    const delTransaction = () => {
        db.collection('transactions').doc(id).delete()
            .then(() => {
                setShowAlert({
                    variant: 'success',
                    status: true,
                    msg: 'Deleted Successfully'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
                setShowDelModal(false)
            }).catch(() => {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Something Went Wrong! Try Again'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            })
    }
    return (
        <tr
            style={{
                backgroundColor: type === 'credit' ? '#CDFFBC' : '#FFCCCC',
            }}
        >
            <td>{id}</td>
            <td>{user}</td>
            <td>{amount}</td>
            <td>{remarks}</td>
            <td>{datetime}</td>
            <td>
                <div className="btn btn-danger mx-1" onClick={e => setShowDelModal(true)} >Delete</div>
                <div className="btn btn-primary mx-1" onClick={e => setShowEditModal(true)}>Edit</div>
            </td>
            <Modal show={showEditModal} onHide={e => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Transaction</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editTransaction}>
                    <Modal.Body>

                        <Form.Group className='my-2' controlId="Amount">
                            <Form.Control type="number" value={editAmount} placeholder="Transaction Amount" onChange={e => setEditAmount(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="Remarks">
                            <Form.Control type="text" value={editRemarks} placeholder="Transaction Remarks" onChange={e => setEditRemarks(e.target.value)} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={editTransaction}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDelModal} onHide={e => setShowDelModal(false)}>
                <Modal.Header>
                    <Modal.Title>Delete Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowDelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={delTransaction}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </tr>
    )
}

export default Transaction
