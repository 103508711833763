
import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { useAuthContext } from '../../contexts/AuthContext';
import YTVideos from './../../components/Screens/YTVideos/index';
import Links from './../../components/Screens/Links/index';
import Events from '../../components/Screens/Events';
import Tags from '../../components/Screens/Tags';
import Team from '../../components/Screens/Team';
import Welcome from './Welcome';
import Resources from '../../components/Screens/Resources';
import DoCoCoins from '../../components/Screens/DoCoCoins';
import Users from '../../components/Screens/Users';
const Dashboard = () => {
    const tabs = [
        {
            name: 'Live Events',
            component: <Events />,
            key: 'liveEvents'
        },
        {
            name: 'Recorded Events',
            component: <YTVideos />,
            key: 'recordedEvents'
        },
        {
            name: 'Links',
            component: <Links />,
            key: 'links'
        },
        {
            name: 'Event Tags',
            component: <Tags />,
            key: 'tags'
        },
        {
            name: 'Team',
            component: <Team />,
            key: 'team'
        },
        {
            name: 'Resources',
            component: <Resources />,
            key: 'resources'
        },
        {
            name: 'DoCoCoins',
            component: <DoCoCoins />,
            key: 'dococoins'
        },
        {
            name: 'Users',
            component: <Users />,
            key: 'users'
        }
    ]
    const { userData } = useAuthContext()
    return userData ? (
        <div>
            <Tabs defaultActiveKey="welcome" >
                <Tab key='welcome' eventKey='welcome' title='Welcome'>
                    <Welcome />
                </Tab>
                {
                    tabs.map(tab => {
                        if (userData.access.includes(tab.key)) {
                            return (
                                <Tab key={tab.key} eventKey={tab.key} title={tab.name}>
                                    {tab.component}
                                </Tab>
                            )
                        }
                        return null
                    })
                }
            </Tabs>
        </div>
    )
        :
        <div>
            <h1>Loading...</h1>
        </div>
}

export default Dashboard