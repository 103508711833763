import React from 'react'
import { Card } from 'react-bootstrap'

const UserCard = ({
    id,
    name,
    email,
    connections,
    dateOfBirth,
    createdAt,
    gender,
    interests,
    profileComplete,
    phoneNumber
}) => {
    return (
        <Card className='m-1' style={{
            width: '100%'
        }}>
            <Card.Header style={{
                backgroundColor: profileComplete === true ? '#CDFFBC' : '#FFCCCC'
            }}>
                <div className="name" style={{
                    fontWeight: '600'
                }}>
                    {name}
                </div>
                <div className="id small"
                    style={{
                        fontWeight: '600',
                        fontSize: '10px'
                    }}>
                    {id}
                </div>
            </Card.Header>
            <Card.Body>
                <div className="contact" style={{
                    fontSize: '14px'
                }}>
                    <a href={`mailto:${email}`}>{email}</a> - <a href={`tel:${phoneNumber}`}>Phone</a>
                </div>
                <div
                    className="dateOfBirth my-1"
                    style={{
                        fontSize: '14px'
                    }}>
                    {dateOfBirth}
                </div>
                <div className="gender my-1"
                    style={{
                        fontSize: '14px',
                        fontWeight: '800',
                        color: gender === 'male' ? 'blueviolet' : 'OrangeRed'
                    }}
                >
                    {gender}
                </div>
                <div className="interests">
                    Interests - {interests.join()}
                </div>
                <div className="connections my-1">
                    Connections - {connections}
                </div>
            </Card.Body>
            <Card.Footer>
                <div className="createdAt">
                    {createdAt}
                </div>
            </Card.Footer>
        </Card >
    )
}

export default UserCard
