import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useAuthContext } from '../../../../contexts/AuthContext';
import { db } from '../../../../firebaseConfig';
import Spacer from '../../../Utilities/Spacer';
import ResourceCard from './ResourceCard';

const ResourceList = ({ resources, number, categories }) => {
    const level = ['beginner', 'intermediate', 'advanced']
    const type = ['video', 'text', 'audio', 'book']
    // Alerts
    const { setShowAlert } = useAuthContext()
    // Modal Controls
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    // Input Refs
    const titleRef = useRef(null)
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [selectedLevel, setSelectedLevel] = useState('')
    const createdAtRef = useRef(null)
    const linkRef = useRef(null)
    // Add Resources to DB
    const addResource = (e) => {
        e.preventDefault()
        db.collection('resources').add({
            link: linkRef.current.value,
            title: titleRef.current.value,
            createdAt: new Date(createdAtRef.current.value),
            category: selectedCategory,
            type: selectedType,
            level: selectedLevel,
            givenBy: {
                community: 'doconation',
                user: ''
            },
            users: []
        }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Resource Added'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            handleClose()
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }
    return (
        <div className="resources">
            <hr />
            <Spacer h='8px' />
            <div className="d-flex justify-content-between align-items-center">
                <h2>Resources ({number})</h2>
                <Button variant="primary" onClick={handleShow}>
                    Add New Resource
                </Button>
            </div>
            <hr />
            <Spacer h='8px' />
            <div className="d-flex flex-wrap justify-content-start">
                {resources.map((resource, index) => (
                    <ResourceCard
                        key={resource.id}
                        title={resource.title}
                        link={resource.link}
                        category={resource.category}
                        createdAt={resource.createdAt.toLocaleDateString('en-US')}
                        level={resource.level}
                        id={resource.id}
                        savedBy={resource.usersLength}
                        type={resource.type}
                        categories={categories}
                    />
                ))}
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add New Resource</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addResource}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" placeholder="Resource Title" ref={titleRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="link">
                            <Form.Control type="text" placeholder="Resource Link" ref={linkRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="date">
                            <Form.Control type="date" placeholder="Published On" ref={createdAtRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="category">
                            <Form.Control as="select" onChange={(e) => setSelectedCategory(e.target.value)} required>
                                <option value="">Select Category</option>
                                {categories.map((category, index) => (
                                    <option key={index} value={category.slug}>{category.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId="level">
                            <Form.Control as="select" onChange={(e) => setSelectedLevel(e.target.value)} required>
                                <option value="">Select Level</option>
                                {level.map((level, index) => (
                                    <option key={index} value={level}>{level}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId="type">
                            <Form.Control as="select" onChange={(e) => setSelectedType(e.target.value)} required>
                                <option value="">Select Type</option>
                                {type.map((type, index) => (
                                    <option key={index} value={type}>{type}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={addResource}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default ResourceList
