import { Button, Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react'
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { db } from '../../../../../firebaseConfig';

const Category = ({ priority, name, id, slug }) => {
    // Modal Controls
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);

    // Input States
    const [inputTitle, setInputTitle] = useState(name)
    const [inputPriority, setInputPriority] = useState(priority)
    const [inputSlug, setInputSlug] = useState(slug)

    // Alert
    const { setShowAlert } = useAuthContext()

    //  Edit Category
    const editCategory = (e) => {
        e.preventDefault()
        db.collection('resourceCategories').doc(id).set({
            priority: inputPriority,
            name: inputTitle,
            slug: inputSlug

        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Category Edited'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    // Delete Category
    const delCategory = () => {
        db.collection('resourceCategories').doc(id).delete()
            .then(() => {
                setShowAlert({
                    variant: 'success',
                    status: true,
                    msg: 'Deleted Successfully'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
                setShowDelModal(false)
            }).catch(() => {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Something Went Wrong! Try Again'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            })
    }


    return (
        <tr >
            <td>{priority}</td>
            <td>{name}</td>
            <td>{slug}</td>
            <td>
                <div className="btn btn-danger mx-1" onClick={e => setShowDelModal(true)} >Delete</div>
                <div className="btn btn-primary mx-1" onClick={e => setShowEditModal(true)}>Edit</div>
            </td>
            <Modal show={showEditModal} onHide={e => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Category</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editCategory}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" value={inputTitle} placeholder="Category Title" onChange={e => setInputTitle(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="slug">
                            <Form.Control type="text" value={inputSlug} placeholder="Category slug" onChange={e => setInputSlug(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="image">
                            <Form.Control type="number" value={inputPriority} placeholder="Category Priority" onChange={e => setInputPriority(e.target.value)} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={editCategory}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDelModal} onHide={e => setShowDelModal(false)}>
                <Modal.Header>
                    <Modal.Title>Delete Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowDelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={delCategory}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </tr>
    )
}

export default Category
