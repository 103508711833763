import React from 'react'
import { useAuthContext } from './../../../contexts/AuthContext';

const Welcome = () => {
    const { userData } = useAuthContext()
    return (
        <div>
            <p>
                Hello, {userData.name}
            </p>
            <p>
                {userData.email}
            </p>
        </div>
    )
}

export default Welcome
