import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { db } from './../../../firebaseConfig';
import Link from './Link/index';
import { useAuthContext } from './../../../contexts/AuthContext';
const Links = () => {
    // Links
    const [links, setLinks] = useState([])

    // Alerts
    const { setShowAlert } = useAuthContext()

    // Modal Controls
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // Input Refs
    const titleRef = useRef(null)
    const urlRef = useRef(null)
    const priorityRef = useRef(null)

    useEffect(() => {
        db.collection('links').orderBy('priority', 'asc').onSnapshot(
            snapshot => {
                setLinks(snapshot.docs.map(
                    doc => ({
                        id: doc.id,
                        title: doc.data().title,
                        url: doc.data().url,
                        priority: doc.data().priority
                    })
                ))
            }
        )
    }, [])

    // Add Links to DB
    const addLinks = (e) => {
        e.preventDefault()
        db.collection('links').add({
            url: urlRef.current.value,
            priority: priorityRef.current.value,
            title: titleRef.current.value
        }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Link Added'
            })
            handleClose()
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)

        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    return (
        <div className='d-flex flex-column py-1'>
            <div className="my-2">
                <Button variant="primary" onClick={handleShow}>
                    Add New Link
                </Button>
            </div>
            <Table className="my-2" striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>URL</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        links.map(({ id, title, url, priority }) => (
                            <Link
                                id={id}
                                key={id}
                                title={title}
                                priority={priority}
                                url={url}
                            />
                        ))
                    }

                </tbody>
            </Table>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add New Link</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addLinks}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" placeholder="Link Title" ref={titleRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="url">
                            <Form.Control type="text" placeholder="Link URL" ref={urlRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="priority">
                            <Form.Control type="number" placeholder="Link Priority" ref={priorityRef} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={addLinks}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>

    )
}

export default Links
