import React, { useRef, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { useAuthContext } from '../../../../contexts/AuthContext'
import { db } from '../../../../firebaseConfig'
import Spacer from '../../../Utilities/Spacer'
import Category from './Category'

const Categories = ({ categories }) => {
    // Alerts
    const { setShowAlert } = useAuthContext()
    // Modal Controls
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    // Input Refs
    const titleRef = useRef(null)
    const priorityRef = useRef(null)
    const slugRef = useRef(null)
    // Add Category to DB
    const addCategory = (e) => {
        e.preventDefault()
        db.collection('resourceCategories').add({
            priority: priorityRef.current.value,
            name: titleRef.current.value,
            slug: slugRef.current.value
        }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Category Added'
            })
            handleClose()
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)

        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }
    return (
        <div className="categories">
            <hr />
            <Spacer h='8px' />
            <div className="d-flex justify-content-between align-items-center">
                <h2>Categories</h2>
                <Button variant="primary" onClick={handleShow}>
                    Add New Tag
                </Button>
            </div>
            <hr />
            <Spacer h='8px' />
            <div className="categoryList">
                <Table className="my-2" striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Slug</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            categories.map(category => (
                                <Category
                                    id={category.id}
                                    key={category.id}
                                    name={category.name}
                                    slug={category.slug}
                                    priority={category.priority}
                                />
                            ))
                        }

                    </tbody>
                </Table>

            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add New Link</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addCategory}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" placeholder="Category Title" ref={titleRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" placeholder="Category Slug" ref={slugRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="priority">
                            <Form.Control type="number" placeholder="Category Priority" ref={priorityRef} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={addCategory}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default Categories
