import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import AdminRoute from './routes/AdminRoute';
import Dashboard from './screens/Dashboard/Dashboard';
import SignIn from './screens/SignIn'

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <AdminRoute path="/" component={Dashboard} exact />
          <Route path="/signin" component={SignIn} exact />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;