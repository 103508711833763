import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { db } from './../../../firebaseConfig';
import Spacer from './../../Utilities/Spacer/index';
import Categories from './Categories';
import ResourceList from './ResourceList';

const Resources = () => {
    const [resources, setResources] = useState([])
    const [totalResources, setTotalResources] = useState(0)
    const [resourceCategories, setResourceCategories] = useState([])
    useEffect(() => {
        db.collection('resources').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const resources = snapshot.docs.map(doc => ({
                id: doc.id,
                category: doc.data().category,
                title: doc.data().title,
                type: doc.data().type,
                link: doc.data().link,
                level: doc.data().level,
                createdAt: doc.data().createdAt.toDate(),
                usersLength: doc.data().users.length,
            }
            ))
            setResources(resources)
            setTotalResources(resources.length)
        })
        db.collection('resourceCategories').orderBy('priority', 'asc').onSnapshot(snapshot => {
            const resourceCategories = snapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
                slug: doc.data().slug,
                priority: doc.data().priority,
            }))
            setResourceCategories(resourceCategories)
        })
    }, [])
    return (
        <div>
            <hr />
            <Spacer h='8px' />
            <Tabs defaultActiveKey='none'>
                <Tab key='categories' eventKey='categories' title='Categories'>
                    <Categories categories={resourceCategories} />
                    <Spacer h='24px' />
                </Tab>
                <Tab key='resources' eventKey='resources' title='Resources'>
                    <ResourceList resources={resources} number={totalResources} categories={resourceCategories} />
                </Tab>
            </Tabs>


        </div>
    )
}

export default Resources
