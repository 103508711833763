import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useAuthContext } from './../../../contexts/AuthContext';
import { db } from './../../../firebaseConfig';
import TeamCard from './TeamCard';

const Team = () => {
    // team State
    const [team, setTeam] = useState([]);

    // Modal Controls
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)

    // Alerts
    const { setShowAlert } = useAuthContext()

    // Input Refs
    const nameRef = useRef(null)
    const departmentRef = useRef(null)
    const imageRef = useRef(null)
    const hashtagRef = useRef(null)
    const quoteRef = useRef(null)
    const githubRef = useRef(null)
    const portfolioRef = useRef(null)
    const linkedinRef = useRef(null)
    const twitterRef = useRef(null)
    const instagramRef = useRef(null)

    //  Fetch teamData from Firebase firestore
    useEffect(() => {
        db.collection('team').onSnapshot(
            snapshot => {
                setTeam(snapshot.docs.map(
                    doc => ({
                        id: doc.id,
                        image: doc.data().image,
                        department: doc.data().department,
                        name: doc.data().name,
                        hashtag: doc.data().hashtag,
                        quote: doc.data().quote,
                        github: doc.data().github,
                        portfolio: doc.data().portfolio,
                        linkedin: doc.data().linkedin,
                        twitter: doc.data().twitter,
                        instagram: doc.data().instagram
                    })
                ))
            })
    }, [])

    // Add member to DB
    const addMember = (e) => {
        e.preventDefault()
        console.log(githubRef.current.value);
        console.log(typeof githubRef.current.value);
        console.log(linkedinRef.current.value);
        db.collection('team').add({
            image: imageRef.current.value,
            department: departmentRef.current.value,
            name: nameRef.current.value,
            hashtag: hashtagRef.current.value,
            quote: quoteRef.current.value,
            linkedin: linkedinRef.current.value,
            instagram: instagramRef.current.value !== '' ? instagramRef.current.value : null,
            twitter: twitterRef.current.value !== '' ? twitterRef.current.value : null,
            github: githubRef.current.value !== '' ? githubRef.current.value : null,
            portfolio: portfolioRef.current.value !== '' ? portfolioRef.current.value : null,

        }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Member Added'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            handleClose()
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    return (
        <div>
            <div className='d-flex flex-column py-3'>
                <div className="header">
                    <Button variant="primary" onClick={handleShow}>
                        Add Member
                    </Button>
                </div>
            </div>
            <div className=' py-1 d-flex flex-wrap'>
                {team.map(({ id, image, name, department, hashtag, quote, github, linkedin, twitter, instagram, portfolio }) => (
                    <TeamCard
                        id={id}
                        key={id}
                        image={image}
                        name={name}
                        department={department}
                        hashtag={hashtag}
                        quote={quote}
                        github={github}
                        linkedin={linkedin}
                        twitter={twitter}
                        instagram={instagram}
                        portfolio={portfolio}
                    />
                ))}
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add New Member</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addMember}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="name">
                            <Form.Control type="text" placeholder="Member Name" ref={nameRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="department">
                            <Form.Control type="text" placeholder="Member Department" ref={departmentRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="image">
                            <Form.Control type="text" placeholder="Member Image URL" ref={imageRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="hashtag">
                            <Form.Control type="text" placeholder="Member Hashtag" ref={hashtagRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="quote">
                            <Form.Control as='textarea' type="text" placeholder="Member Quote" ref={quoteRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="linkedin">
                            <Form.Control type="text" placeholder="Member Linkedin (required)" ref={linkedinRef} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="github">
                            <Form.Control type="text" placeholder="Member GitHub" ref={githubRef} />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="instagram">
                            <Form.Control type="text" placeholder="Member Instagram" ref={instagramRef} />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="twitter">
                            <Form.Control type="text" placeholder="Member Twitter" ref={twitterRef} />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="portfolio">
                            <Form.Control type="text" placeholder="Member Portfolio" ref={portfolioRef} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={addMember}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default Team
