import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { useAuthContext } from '../../../../contexts/AuthContext';
import './style.css'
import { db } from './../../../../firebaseConfig';

const TeamCard = ({ id, image, name, department, hashtag, quote, github, linkedin, twitter, instagram, portfolio }) => {
    // Modal Controls
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);

    // Alert
    const { setShowAlert } = useAuthContext()

    // Input State
    const [inputName, setInputName] = useState(name)
    const [inputImage, setInputImage] = useState(image)
    const [inputDepartment, setInputDepartment] = useState(department)
    const [inputHashtag, setInputHashtag] = useState(hashtag)
    const [inputQuote, setInputQuote] = useState(quote)
    const [inputGithub, setInputGithub] = useState(github)
    const [inputLinkedin, setInputLinkedin] = useState(linkedin)
    const [inputTwitter, setInputTwitter] = useState(twitter)
    const [inputInstagram, setInputInstagram] = useState(instagram)
    const [inputPortfolio, setInputPortfolio] = useState(portfolio)

    //  Edit Member Details
    const editMember = (e) => {
        e.preventDefault()
        db.collection('team').doc(id).set({
            image: inputImage,
            department: inputDepartment,
            name: inputName,
            hashtag: inputHashtag,
            quote: inputQuote,
            linkedin: inputLinkedin,
            instagram: inputInstagram !== '' ? inputInstagram : null,
            twitter: inputTwitter !== '' ? inputTwitter : null,
            github: inputGithub !== '' ? inputGithub : null,
            portfolio: inputPortfolio !== '' ? inputPortfolio : null
        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Member Edited'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    // Delete Event Details
    const delMember = () => {
        db.collection('team').doc(id).delete()
            .then(() => {
                setShowAlert({
                    variant: 'success',
                    status: true,
                    msg: 'Deleted Successfully'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
                setShowDelModal(false)
            }).catch(() => {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Something Went Wrong! Try Again'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            })
    }
    return (
        <Card className='m-1' style={{ width: '250px' }}>
            <Card.Img variant="top" src={image} />
            <Card.Body className='d-flex flex-column'>
                <Card.Title>
                    {name}
                </Card.Title>
                <div className='cardBody' >
                    <div className="department">
                        {department}
                        <br />
                        <small>
                            <i className="fa fa-hashtag" />
                            {hashtag}
                        </small>
                    </div>
                    <div className="quote">
                        "{quote}"
                    </div>
                    <div className='links'>
                        {linkedin != null ?
                            <a className='link' href={linkedin}><i className='fab fa-linkedin'></i></a>
                            :
                            ''
                        }
                        {
                            twitter != null ?
                                <a className='link' href={twitter}><i className='fab fa-twitter'></i></a>
                                :
                                ''
                        }
                        {
                            instagram != null ?
                                <a className='link' href={instagram}><i className='fab fa-instagram'></i></a>
                                :
                                ''
                        }
                        {
                            portfolio != null ?
                                <a className='link' href={portfolio}><i className='fas fa-link'></i></a>
                                :
                                ''
                        }
                        {
                            github != null ?
                                <a className='link' href={github}><i className='fab fa-github'></i></a>
                                :
                                ''
                        }
                    </div>

                </div>
                <div className="btns d-flex justify-content-between">
                    <Button variant="danger" onClick={e => setShowDelModal(true)}>Delete</Button>
                    <Button variant="primary" onClick={e => setShowEditModal(true)}>Edit</Button>
                </div>
            </Card.Body>


            <Modal show={showEditModal} onHide={e => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editMember}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="name">
                            <Form.Control type="text" value={inputName} placeholder="Member Name" onChange={e => setInputName(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="department">
                            <Form.Control type="text" value={inputDepartment} placeholder="Member Department" onChange={e => setInputDepartment(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="image">
                            <Form.Control type="text" value={inputImage} placeholder="Member Image URL" onChange={e => setInputImage(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="hashtag">
                            <Form.Control type="text" value={inputHashtag} placeholder="Member Hashtag" onChange={e => setInputHashtag(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="quote">
                            <Form.Control type="text" value={inputQuote} placeholder="Member Quote" onChange={e => setInputQuote(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="linkedin">
                            <Form.Control type="text" value={inputLinkedin} placeholder="Member Linkedin" onChange={e => setInputLinkedin(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="twitter">
                            <Form.Control type="text" value={inputTwitter} placeholder="Member Twitter" onChange={e => setInputTwitter(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="instagram">
                            <Form.Control type="text" value={inputInstagram} placeholder="Member Instagram" onChange={e => setInputInstagram(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="portfolio">
                            <Form.Control type="text" value={inputPortfolio} placeholder="Member Portfolio" onChange={e => setInputPortfolio(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="github">
                            <Form.Control type="text" value={inputGithub} placeholder="Member Github" onChange={e => setInputGithub(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={editMember}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            <Modal show={showDelModal} onHide={e => setShowDelModal(false)}>
                <Modal.Header>
                    <Modal.Title>Delete Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowDelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={delMember}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    )
}

export default TeamCard
