import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { useAuthContext } from './../../../../contexts/AuthContext';
import './style.css'
import { db, storage } from './../../../../firebaseConfig';

const EventCard = ({ link, title, subtitle, image, id, tags, date, status, time }) => {
    // Status Array
    const statuses = ['Planning', 'Published', 'Live', 'Over']
    // Modal Controls
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);

    // Alert
    const { setShowAlert } = useAuthContext()
    // Input State
    const [inputTitle, setInputTitle] = useState(title)
    const [inputSubtitle, setInputSubtitle] = useState(subtitle)
    const [inputLink, setInputLink] = useState(link)
    const [inputImage, setInputImage] = useState(image)
    const [inputTags, setInputTags] = useState(tags)
    const [inputDate, setInputDate] = useState(date)
    const [inputTime, setInputTime] = useState(time)
    const [curStatus, setCurStatus] = useState(status)
    const [progress, setProgress] = useState(null)
    //  Edit Event Details
    const editEvent = (e) => {
        e.preventDefault()
        db.collection('events').doc(id).set({
            image: inputImage,
            eventLink: inputLink,
            datetime: new Date(inputDate + ' ' + inputTime),
            subtitle: inputSubtitle,
            tags: inputTags.split(','),
            title: inputTitle
        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Event Edited'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }

    // Upload Image
    const type = ['image/png', 'image/jpeg', 'image/jpg']
    const uploadImage = (e) => {
        setInputImage('')
        const file = e.target.files[0]
        if (file) {
            if (type.includes(file.type)) {
                const uploadTask = storage.ref(`events/${file.name}`).put(file)
                uploadTask.on('state_changed', (snapshot) => {
                    // progress function
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    setProgress(progress)
                }, (error) => {
                    // error function
                    console.log(error)
                    setShowAlert({
                        variant: 'danger',
                        status: true,
                        msg: 'Something Went Wrong! Try Again'
                    })
                    setTimeout(() => {
                        setShowAlert({
                            variant: '',
                            status: false,
                            msg: ''
                        })
                    }, 2000)
                }, () => {
                    // complete function
                    storage.ref('events').child(file.name).getDownloadURL().then(url => {
                        setInputImage(url)
                    })
                })
            } else {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Invalid File Type'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            }
        }
    }

    // Delete Event Details
    const delEvent = () => {
        db.collection('events').doc(id).delete()
            .then(() => {
                setShowAlert({
                    variant: 'success',
                    status: true,
                    msg: 'Deleted Successfully'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
                setShowDelModal(false)
            }).catch(() => {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Something Went Wrong! Try Again'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            })
    }


    useEffect(() => {
        db.collection('events').doc(id).set({
            status: curStatus
        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Status Updated'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }, [curStatus, id, setShowAlert])
    return (
        <Card className='m-1' style={{ width: '250px' }}>
            <Card.Img variant="top" src={image} />
            <Card.Body className='d-flex flex-column'>
                <Card.Title>
                    {title}
                </Card.Title>
                <div className='cardBody' >
                    <span className='subtitle'>
                        {subtitle}
                    </span>
                    <div>
                        <span className="date">
                            Date: {date}
                        </span>
                        <br />
                        <span className="tags">
                            Tags: {tags}
                        </span>
                        <br />
                        <span className="eventLink">
                            Link: <a href={link}>Click Here</a>
                        </span>
                        <br />
                        <span className="statuses">
                            {statuses.map((item, i) => (
                                <div
                                    className={item === curStatus
                                        ? "status active"
                                        : "status"
                                    }
                                    key={i}
                                    onClick={(e) => setCurStatus(item)}
                                >
                                    {item}
                                </div>
                            )
                            )}
                        </span>
                    </div>
                </div>
                <div className="btns d-flex justify-content-between">
                    <Button variant="danger" onClick={e => setShowDelModal(true)}>Delete</Button>
                    <Button variant="primary" onClick={e => setShowEditModal(true)}>Edit</Button>
                </div>
            </Card.Body>
            <Modal show={showEditModal} onHide={e => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editEvent}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" value={inputTitle} placeholder="Event Title" onChange={e => setInputTitle(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="subtitle">
                            <Form.Control type="text" value={inputSubtitle} placeholder="Event Subtitle" onChange={e => setInputSubtitle(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="file">
                            <Form.Control type="file" onChange={uploadImage} />
                            <br />
                            {progress && progress < 100 ? (<div className='text-danger m-2'>
                                Uploading... {progress}%
                            </div>)
                                : (progress === 100 && <div className='text-success m-2'>
                                    Uploaded
                                </div>)
                            }
                        </Form.Group>
                        <Form.Group className='my-2' controlId="image">
                            <Form.Control className='disabled' type="text" value={inputImage} placeholder="Event Image URL" required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="link">
                            <Form.Control type="text" value={inputLink} placeholder="Event Link" onChange={e => setInputLink(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="tags">
                            <Form.Control type="text" value={inputTags} placeholder="Event Tags - Use Comma, No Space" onChange={e => setInputTags(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="date">
                            <Form.Control type="text" value={inputDate} placeholder="Event Date (yyyy-mm-dd)" onChange={e => setInputDate(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="time">
                            <Form.Control type="text" value={inputTime} placeholder="Event Time (00:00)" onChange={e => setInputTime(e.target.value)} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={editEvent}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDelModal} onHide={e => setShowDelModal(false)}>
                <Modal.Header>
                    <Modal.Title>Delete Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowDelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={delEvent}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    )
}

export default EventCard
