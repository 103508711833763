import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { db } from '../../../../../firebaseConfig';
import './style.css'

const ResourceCard = ({ link, title, category, id, createdAt, level, savedBy, type, categories }) => {
    const levels = ['beginner', 'intermediate', 'advanced']
    const types = ['video', 'text', 'audio', 'book']
    // Modal Controls
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);

    // Alert
    const { setShowAlert } = useAuthContext()

    // Input State
    const [inputTitle, setInputTitle] = useState(title)
    const [inputLink, setInputLink] = useState(link)
    const [inputDate, setInputDate] = useState(createdAt)
    const [inputLevel, setInputLevel] = useState(level)
    const [inputType, setInputType] = useState(type)
    const [inputCategory, setInputCategory] = useState(category)

    //  Edit Resource Details
    const editResource = (e) => {
        e.preventDefault()
        db.collection('resources').doc(id).set({
            title: inputTitle,
            link: inputLink,
            createdAt: new Date(inputDate),
            level: inputLevel,
            type: inputType,
            category: inputCategory
        }, { merge: true }).then(() => {
            setShowAlert({
                variant: 'success',
                status: true,
                msg: 'Resource Edited'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
            setShowEditModal(false)
        }).catch(() => {
            setShowAlert({
                variant: 'danger',
                status: true,
                msg: 'Something Went Wrong! Try Again'
            })
            setTimeout(() => {
                setShowAlert({
                    variant: '',
                    status: false,
                    msg: ''
                })
            }, 2000)
        })
    }
    // Delete Resource Details
    const delResource = () => {
        db.collection('resources').doc(id).delete()
            .then(() => {
                setShowAlert({
                    variant: 'success',
                    status: true,
                    msg: 'Deleted Successfully'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
                setShowDelModal(false)
            }).catch(() => {
                setShowAlert({
                    variant: 'danger',
                    status: true,
                    msg: 'Something Went Wrong! Try Again'
                })
                setTimeout(() => {
                    setShowAlert({
                        variant: '',
                        status: false,
                        msg: ''
                    })
                }, 2000)
            })
    }

    return (
        <Card className='m-1' style={{ width: '200px' }}>
            <Card.Body className='d-flex flex-column'>
                <Card.Title>{title}</Card.Title>
                <div className='cardBody' >
                    <span className='subtitle'>
                        {category}
                    </span>
                    <div className='details'>
                        <span className="date">
                            Created: {createdAt}
                        </span>
                        <br />
                        <span className="level">
                            Level: {level}
                        </span>
                        <br />
                        <span className="type">
                            Type: {type}
                        </span>
                        <br />
                        <span className="savedBy">
                            Saved By: {savedBy}
                        </span>
                        <br />
                        <span className="Link">
                            Link: <a href={link}>Click Here</a>
                        </span>
                    </div>
                </div>
                <div className="btns d-flex justify-content-between">
                    <Button variant="danger" onClick={e => setShowDelModal(true)}>Delete</Button>
                    <Button variant="primary" onClick={e => setShowEditModal(true)}>Edit</Button>
                </div>

            </Card.Body>
            <Modal show={showEditModal} onHide={e => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Resource</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editResource}>
                    <Modal.Body>
                        <Form.Group className='my-2' controlId="title">
                            <Form.Control type="text" value={inputTitle} placeholder="Resource Title" onChange={e => setInputTitle(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="link">
                            <Form.Control type="text" value={inputLink} placeholder="Resource Link" onChange={e => setInputLink(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="date">
                            <Form.Control type="text" value={inputDate} placeholder="Published On" onChange={e => setInputDate(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className='my-2' controlId="level">
                            <Form.Control as="select" value={inputLevel} onChange={e => setInputLevel(e.target.value)} required>
                                <option value="">Select Level</option>
                                {levels.map(level => <option key={level} value={level}>{level}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId="type">
                            <Form.Control as="select" value={inputType} onChange={e => setInputType(e.target.value)} required>
                                <option value="">Select Type</option>
                                {types.map(type => <option key={type} value={type}>{type}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId="category">
                            <Form.Control as="select" value={inputCategory} onChange={e => setInputCategory(e.target.value)} required>
                                <option value="">Select Category</option>
                                {categories.map(category => <option key={category.slug} value={category.slug}>{category.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' onSubmit={editResource}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showDelModal} onHide={e => setShowDelModal(false)}>
                <Modal.Header>
                    <Modal.Title>Delete Resource</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowDelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={delResource}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    )
}

export default ResourceCard
