import React from 'react'
import { Alert } from 'react-bootstrap'
import Navbar from './Navbar'
import { useAuthContext } from './../../contexts/AuthContext';

const Layout = ({ children }) => {
    const { showAlert } = useAuthContext()
    return (
        <div>
            <Navbar />
            <div className="container mt-2">
                {
                    showAlert.status
                        ?
                        (<Alert variant={showAlert.variant} className='text-center'>
                            {showAlert.msg}
                        </Alert>)
                        :
                        (<div></div>)
                }
            </div>
            <div className="container py-3">
                {children}
            </div>
        </div>
    )
}

export default Layout
